<template>
  <div align="center">
    <div
      fluid
      v-if="isVisible"
      :style="{
        backgroundImage:
          'url(' + require('@/assets/images/' + background_mobile) + ')',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
        backgroundSize: 'cover',
      }"
      class="
        background-container
        d-flex
        flex-column
        align-center
        justify-center
        px-4
      "
    >
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="40"
          height="40"
          rx="8"
          :fill="WEBAPPPRO_FILL_COLOR_FOR_TREATMENT"
        />
        <path
          d="M10.2437 8.5809C9.78421 8.12147 9.03931 8.12147 8.57987 8.5809C8.12043 9.04035 8.12043 9.78525 8.57987 10.2447L29.7563 31.4211C30.2158 31.8806 30.9607 31.8806 31.4201 31.4211C31.8795 30.9617 31.8795 30.2168 31.4201 29.7574L25.1685 23.5057C25.4401 23.0536 25.3811 22.4583 24.9913 22.0686C24.1094 21.1867 23.0524 20.5869 21.932 20.2693L19.3342 17.6715C21.9596 17.4865 24.6479 18.3976 26.6551 20.4048C27.1145 20.8642 27.8594 20.8642 28.3188 20.4048C28.7782 19.9453 28.7782 19.2004 28.3188 18.7409C25.3238 15.7459 21.1155 14.7033 17.2759 15.6132L15.4389 13.7761C20.2716 11.9532 25.8555 13.119 29.7276 17.2737C30.1706 17.749 30.9151 17.7753 31.3904 17.3323C31.8656 16.8893 31.8919 16.1448 31.4489 15.6695C26.6426 10.5125 19.5415 9.28167 13.6399 11.9772L10.2437 8.5809Z"
          :fill="WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT"
        />
        <path
          d="M8.55113 15.6678C8.10813 16.1431 8.13434 16.8876 8.60966 17.3306C9.08499 17.7736 9.82942 17.7473 10.2724 17.272C10.7629 16.7458 11.2808 16.2675 11.8211 15.8372L10.148 14.1641C9.59283 14.6214 9.05916 15.1227 8.55113 15.6678Z"
          :fill="WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT"
        />
        <path
          d="M13.3409 17.3584C12.7566 17.7605 12.2006 18.2212 11.6811 18.7407C11.2216 19.2002 11.2216 19.945 11.6811 20.4046C12.1405 20.864 12.8854 20.864 13.3448 20.4046C13.8682 19.8812 14.4378 19.4323 15.0406 19.0581L13.3409 17.3584Z"
          :fill="WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT"
        />
        <path
          d="M16.7656 20.7822C16.1328 21.1085 15.5389 21.5368 15.0085 22.0673C14.5491 22.5267 14.5491 23.2716 15.0085 23.7311C15.468 24.1905 16.2129 24.1905 16.6723 23.7311C17.2204 23.1829 17.87 22.7983 18.5606 22.5772L16.7656 20.7822Z"
          :fill="WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT"
        />
        <path
          d="M20 29.4119C18.7005 29.4119 17.6471 28.3585 17.6471 27.059C17.6471 25.7595 18.7005 24.7061 20 24.7061C21.2996 24.7061 22.353 25.7595 22.353 27.059C22.353 28.3585 21.2996 29.4119 20 29.4119Z"
          :fill="WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT"
        />
      </svg>

      <h1
        class="text-center"
        :style="{
          color: color1,
          marginTop: '20px',
          marginBottom: '20px',
          marginRight: '20px',
          fontSize: fontSize1,
          fontFamily: fontFamily1,
          fontWeight: 'regular',
          'text-transform': 'uppercase',
        }"
      >
        Connexion perdue
      </h1>

      <p class="text-center">
        <strong
          :style="{
            color: color1,
            fontSize: fontSize3,
            fontFamily: fontFamily1,
          }"
          >Veuillez scanner le QR code d’authentification ou cliquer sur le lien
          fourni par votre agence.</strong
        >
      </p>

      <p
        class="text-center"
        :style="{
          color: color1,
          fontSize: fontSize3,
          fontFamily: fontFamily1,
          'font-weight': 'regular',
        }"
      >
        Ce service n’est disponible que sur smartphone (iPhone, Android), ou sur
        tablette.
      </p>
    </div>
  </div>
</template>

<script>
import { localStorageService } from '@/services/localStorage.service'
export default {
  name: 'NotAuthorized',
  data() {
    return {
      isVisible: false,
      color1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      color2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      fontFamily1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      fontSize1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      fontSize2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      fontSize3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      buttonFontSize:
        localStorageService.getStyle(
          location.hostname,
          'WEBAPPPRO_BUTTON_FONT_SIZE',
        ) + 'px',
      borderRound:
        localStorageService.getStyle(
          location.hostname,
          'WEBAPPPRO_CARD_BORDER_ROUND',
        ) + 'px',
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_ICON_COLOR_INFO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO_2',
      ),
      WEBAPPPRO_ICON_COLOR_WARNING_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_WARNING_2',
      ),
      WEBAPPPRO_FILL_COLOR_FOR_TREATMENT: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FILL_COLOR_FOR_TREATMENT',
      ),
      WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_STROKE_COLOR_FOR_TREATMENT',
      ),
    }
  },
  mounted() {
    const shortLiveApiKey = localStorageService.getValue('shortliveApiKey')
    if (shortLiveApiKey !== undefined) {
      localStorageService.clearAll()
      this.$router.push({
        name: 'Authentication',
        params: { id: shortLiveApiKey },
      })
      return
    }
    this.isVisible = true
  },
}
</script>

<style scoped>
center {
  margin: 15vw;
}

h1 {
  color: var(--border);
  font-size: 2em;
}
</style>
