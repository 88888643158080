var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_desktop) + ')',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"accueil-drawer",attrs:{"rail":_vm.rail,"permanent":""}},[_c('Accueil')],1),_c('div',{staticClass:"\n      container-small\n      d-flex\n      flex-column\n      justify-center\n      align-center\n      photo-capture-container\n    "},[_c('div',{staticClass:"photo-status"},[_c('h2',{staticClass:"text-left mb-3 ml-13",style:({
          color: _vm.WEBAPPPRO_TEXT_COLOR_4,
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_2,
          fontSize: _vm.WEBAPPPRO_FONT_SIZE_2,
          fontWeight: 700,
          marginTop: '10px',
        })},[_vm._v(" Paramétrage ")]),_c('div',{staticClass:"d-flex flex-row flex-wrap mt-4 ma-5"},[(_vm.isCreditEmpty)?_c('div',[_c('v-card',{staticClass:"text-center px-7",style:({
              borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
              paddingTop: '20px',
            }),attrs:{"width":"357","height":"120"}},[_c('div',{staticClass:"row",attrs:{"align":"center"}},[_c('div',{staticClass:"col d-flex text-left",style:({
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  paddingTop: '20px',
                  fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
                  fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
                  fontWeight: 600,
                })},[_c('p',[_vm._v("Vous êtes revendeur "),_c('br'),_vm._v("de code ePhoto")])]),_c('div',{staticClass:"col d-flex align-center"},[_c('v-switch',{attrs:{"inset":"","color":_vm.reseller ? _vm.WEBAPPPRO_TEXT_COLOR_1 : ''},model:{value:(_vm.reseller),callback:function ($$v) {_vm.reseller=$$v},expression:"reseller"}})],1)])])],1):_vm._e(),_c('div',{staticClass:"text-left ma-5"},[_c('p',{staticClass:"mb-1",style:({
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
              fontSize: _vm.WEBAPPPRO_FONT_SIZE_3,
              fontWeight: 600,
              paddingLeft: '10px',
            })},[_vm._v(" Je reçois mes emails sur ")]),_c('v-text-field',{staticClass:"rounded-bottom ma-2",style:({ borderRadius: '8px', width: '400px' }),attrs:{"placeholder":_vm.cleanEmail,"solo":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('svg',{attrs:{"width":"17","height":"12","viewBox":"0 0 17 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M14.875 0H2.125C0.949167 0 0 1.005 0 2.25V9.75C0 10.995 0.949167 12 2.125 12H14.875C16.0508 12 17 10.995 17 9.75V2.25C17 1.005 16.0508 0 14.875 0ZM14.2092 1.5L8.5 5.13L2.79083 1.5H14.2092ZM14.875 10.5H2.125C1.72833 10.5 1.41667 10.17 1.41667 9.75V2.37L8.13167 6.645C8.35833 6.795 8.64167 6.795 8.85417 6.645L15.5692 2.37V9.75C15.5692 10.17 15.2575 10.5 14.8608 10.5H14.875Z","fill":"#C1C1C1"}})])]},proxy:true}]),model:{value:(_vm.emailRedirection),callback:function ($$v) {_vm.emailRedirection=$$v},expression:"emailRedirection"}})],1)]),_c('div',{staticClass:"d-flex flex-row flex-wrap mt-10"},[_vm._l((_vm.itemsEmail),function(item,i){return _c('MiniCards3Checkbox',{key:i,staticClass:"ma-5",attrs:{"title":item.title,"description":item.description,"miniDescription":item.miniDescription,"titleX1":item.titleX1,"titleX2":item.titleX2,"checkboxX1Y1Title":"Oui","checkboxX1Y2Title":"Non","checkboxX1Y3Title":'Par défaut (' +
            (_vm.partnerPlanDefaultConfig.includes(item.typeX1) ? 'Oui)' : 'Non)'),"checkboxX2Y1Title":"Oui","checkboxX2Y2Title":"Non","checkboxX2Y3Title":'Par défaut (' +
            (_vm.partnerPlanDefaultConfig.includes(item.typeX2) ? 'Oui)' : 'Non)'),"checkboxX1Y1Value":"Oui","checkboxX1Y2Value":"Non","checkboxX1Y3Value":"Default","checkboxX2Y1Value":"Oui","checkboxX2Y2Value":"Non","checkboxX2Y3Value":"Default","radioValueX1":item.valX1 != null ? item.valX1 : _vm.findEmailConfig(item.typeX1),"radioValueX2":item.valX2 != null ? item.valX2 : _vm.findEmailConfig(item.typeX2),"updateX1":function (rValue) {
              item.valX1 = rValue
            },"updateX2":function (rValue) {
              item.valX2 = rValue
            }}})}),_c('div',{staticClass:"d-flex flex-row"},[_c('v-btn',{staticClass:"mb-5 mt-7",style:({
              color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
              fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
              fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
              borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
              textTransform: 'none',
              marginLeft: '25px',
            }),attrs:{"width":"240px","height":"52px","color":_vm.WEBAPPPRO_BUTTON_COLOR_1},on:{"click":function($event){return _vm.updateReseller()}}},[_vm._v("J’enregistre ")]),_c('v-btn',{staticClass:"mt-7",style:({
              backgroundColor: 'transparent',
              boxShadow: 'none',
              color: _vm.WEBAPPPRO_BUTTON_COLOR_1,
              padding: '0',
              textTransform: 'none',
              marginLeft: '210px',
            }),on:{"click":function($event){return _vm.getInvoice()}}},[_vm._v(" Gérer mes factures ")])],1)],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }