<template>
  <div
    fluid
    :style="{
      backgroundImage:
        'url(' + require('@/assets/images/' + background_desktop) + ')',
      height: '100vh',
      width: '100vw',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
      backgroundSize: 'cover',
    }"
    class="background-container"
  >
    <!--<div style="margin-top: 40px; margin-left: 150px">
      <v-btn
        class="text-none"
        :to="{ name: '' }"
        :disabled="uploading"
        outlined
        :style="{
          'font-family': 'Georama',
          'font-size': '16px',
          'font-weight': '400',
          'line-height': '20px',
          'letter-spacing': '0em',
          padding: '5px 10px',
        }"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        Retour
      </v-btn>
    </div> --->
    <div :rail="rail" permanent class="accueil-drawer">
      <Accueil></Accueil>
    </div>
    >
    <v-container
      class="container-small d-flex flex-column justify-center align-center"
      style="margin-top: 5%"
    >
      <v-card
        class="pa-10"
        :style="{
          borderRadius: WEBAPPPRO_CARD_BORDER_ROUND,
          marginTop: '20px',
          width: '880px',
          height: '640px',
        }"
      >
        <h1
          class="text-center mb-2"
          :style="{
            color: WEBAPPPRO_TEXT_COLOR_4,
            fontFamily: WEBAPPPRO_FONT_FAMILY_2,
            fontSize: WEBAPPPRO_FONT_SIZE_2,
            fontWeight: 700,
            marginTop: '10px',
          }"
        >
          Mandat de prélèvement SEPA
        </h1>
        <div
          style="margin-top: 30px; margin-bottom: 30px"
          class="subtitle-1 text-center"
          :style="{
            fontWeight: 400,
            fontFamily: WEBAPPPRO_FONT_FAMILY_2,
            fontSize: WEBAPPPRO_FONT_SIZE_2,
            lineHeight: '20px',
            textAlign: 'center',
            // color: WEBAPPPRO_TEXT_COLOR_2,
          }"
        >
          <p>
            Activez le prélèvement SEPA en toute simplicité ! Un montant<br />
            symbolique de 1 € sera débité lors de l'activation, puis vous serez
            prélevé en <br />fin de mois uniquement pour le montant exact de
            votre consommation.
          </p>
        </div>
        <div>
          <v-alert type="error" v-if="errors.length">
            <ul>
              <li v-for="error in errors" :key="error.id">{{ error }}</li>
            </ul>
          </v-alert>
          <div class="d-flex flex-row align-center">
            <v-text-field
              label="Nom*"
              v-model="accountName"
              solo
              class="rounded-bottom ma-2"
              :class="{ 'focused-border': isFocused }"
              :style="{ borderRadius: '8px', width: '350px', height: '52px' }"
              @focus="handleFocus('accountName')"
              @blur="handleBlur('accountName')"
            >
              <template v-slot:prepend-inner>
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.26782 4.09205C3.28642 6.35177 5.17362 8.18409 7.48381 8.18409H7.50705C9.8126 8.15681 11.6673 6.2972 11.6347 4.04203C11.6068 1.80505 9.73358 0 7.45127 0C7.43268 0 7.41409 0 7.40014 0C5.12249 0.0272803 3.26782 1.86415 3.26782 4.09205ZM7.27464 1.83233V1.81414H7.5117C8.79463 1.85506 9.80795 2.90081 9.77541 4.1557C9.74288 5.38786 8.70631 6.36995 7.45127 6.36995C7.43268 6.36995 7.40944 6.36995 7.39085 6.36995C6.12187 6.33813 5.12713 5.33785 5.12713 4.09659C5.14573 2.90535 6.08933 1.9369 7.27464 1.83687V1.83233Z"
                    fill="#C1C1C1"
                  />
                  <path
                    d="M14.0703 16C14.5817 16 15 15.5908 15 15.0907V12.881C14.9814 10.8395 13.2708 9.17993 11.1838 9.17993H10.1193C9.88224 9.17993 9.65913 9.27541 9.48714 9.44364L7.51162 11.3396L5.5361 9.44364C5.35947 9.27541 5.1317 9.17993 4.88534 9.17993H3.81624C1.72451 9.17993 0.0185931 10.8349 0 12.881V15.0907C0 15.5908 0.418345 16 0.929656 16C1.44097 16 1.85931 15.5908 1.85931 15.0907V12.881C1.87791 11.8352 2.74713 10.9986 3.81159 10.9986H4.50418L6.56802 12.9719C7.10257 13.472 7.94856 13.472 8.48311 12.9719L10.5191 10.9986H11.1931C12.2529 10.9986 13.1221 11.8352 13.1407 12.8764V15.0907C13.1407 15.5908 13.559 16 14.0703 16Z"
                    fill="#C1C1C1"
                  />
                </svg>
              </template>
              <template v-slot:label>
                <span class="text-align-left" style="color: #c1c1c1">Nom</span>
              </template>
            </v-text-field>
            <v-text-field
              label="Email*"
              v-model="email"
              solo
              class="rounded-bottom ma-2"
              :class="{ 'focused-border': isFocused }"
              :style="{ borderRadius: '8px', width: '350px', height: '52px' }"
              @focus="handleFocus('email')"
              @blur="handleBlur('email')"
            >
              <template v-slot:prepend-inner>
                <svg
                  width="17"
                  height="12"
                  viewBox="0 0 17 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.875 0H2.125C0.949167 0 0 1.005 0 2.25V9.75C0 10.995 0.949167 12 2.125 12H14.875C16.0508 12 17 10.995 17 9.75V2.25C17 1.005 16.0508 0 14.875 0ZM14.2092 1.5L8.5 5.13L2.79083 1.5H14.2092ZM14.875 10.5H2.125C1.72833 10.5 1.41667 10.17 1.41667 9.75V2.37L8.13167 6.645C8.35833 6.795 8.64167 6.795 8.85417 6.645L15.5692 2.37V9.75C15.5692 10.17 15.2575 10.5 14.8608 10.5H14.875Z"
                    fill="#C1C1C1"
                  />
                </svg>
              </template>
              <template v-slot:label>
                <span class="text-align-left" style="color: #c1c1c1"
                  >Email*</span
                >
              </template>
            </v-text-field>
          </div>
          <v-alert type="error" v-if="sepaError.length">
            <ul>
              <li v-for="error in sepaError" :key="error.id">{{ error }}</li>
            </ul>
          </v-alert>
          <div class="v-input v-input--outlined">
            <div class="v-input__control">
              <div id="iban-element" class="iban-input mt-3 mb-3"></div>
            </div>
          </div>
          <v-btn
            type="submit"
            :color="WEBAPPPRO_BUTTON_COLOR_1"
            @click="handleSubmit"
            :loading="loading"
            block
            :style="{
              color: WEBAPPPRO_BUTTON_TEXT_COLOR_1,
              boxShadow: WEBAPPPRO_BUTTON_SHADOW_1,
              textTransform: 'none',
              marginBottom: '10px',
              marginTop: '10px',
              padding: '0px',
              fontFamily: WEBAPPPRO_FONT_FAMILY_3,
              fontSize: WEBAPPPRO_BUTTON_FONT_SIZE,
              fontWeight: 'bold',
              lineHeight: '18px',
              letterSpacing: '0em',
              textAlign: 'center',
              width: '240px',
              height: '57px',
            }"
            >Confirmer le mandat</v-btn
          >
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import { localStorageService } from '@/services/localStorage.service'
import { requestService } from '@/services/request.service'
import Accueil from '../../components/Accueil.vue'

export default {
  name: 'Sepa',
  components: {
    Accueil,
  },
  data() {
    return {
      stripe: null,
      ibanElement: null,
      email: '',
      accountName: '',
      isFocused: {
        email: false,
        accountName: false,
      },
      errors: [],
      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      WEBAPPPRO_ICON_COLOR_INFO: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_ICON_COLOR_INFO',
      ),
      WEBAPPPRO_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1',
      ),
      WEBAPPPRO_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_2',
      ),
      WEBAPPPRO_COLOR_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_3',
      ),
      WEBAPPPRO_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_1',
      ),
      WEBAPPPRO_TEXT_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_2',
      ),
      WEBAPPPRO_TEXT_COLOR_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_3',
      ),
      WEBAPPPRO_TEXT_COLOR_4: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_4',
      ),
      WEBAPPPRO_BUTTON_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_1',
      ),
      WEBAPPPRO_BUTTON_TEXT_COLOR_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_TEXT_COLOR_1',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
      WEBAPPPRO_FONT_FAMILY_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_1',
      ),
      WEBAPPPRO_FONT_FAMILY_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_2',
      ),
      WEBAPPPRO_FONT_SIZE_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_1',
      ),
      WEBAPPPRO_FONT_SIZE_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_2',
      ),
      WEBAPPPRO_FONT_SIZE_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_SIZE_3',
      ),
      WEBAPPPRO_FONT_FAMILY_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_FONT_FAMILY_3',
      ),
      WEBAPPPRO_BUTTON_FONT_SIZE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_FONT_SIZE',
      ),
      WEBAPPPRO_CARD_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_BORDER_ROUND: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_BORDER_ROUND',
      ),
      WEBAPPPRO_BUTTON_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_1',
      ),
      WEBAPPPRO_BUTTON_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_SHADOW_2',
      ),
      background_mobile: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_MOBILE',
      ),
      WEBAPPPRO_CARD_SHADOW_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_1',
      ),
      WEBAPPPRO_CARD_SHADOW_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_2',
      ),
      WEBAPPPRO_CARD_SHADOW_3: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_SHADOW_3',
      ),
      validatedImages: localStorageService.getValue('validatedImages'),
      addOffer: localStorageService.getValue('addOfferId'),
      WEBAPPPRO_LOGO_1: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_1',
      ),
      WEBAPPPRO_LOGO_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_LOGO_2',
      ),
      WEBAPPPRO_CARTE: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARTE',
      ),
      WEBAPPPRO_CARD_COLOR: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_CARD_COLOR',
      ),
      WEBAPPPRO_COLOR_1_FILL_ICON: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_COLOR_1_FILL_ICON',
      ),
      WEBAPPPRO_TEXT_COLOR_ETAPES: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_TEXT_COLOR_ETAPES',
      ),
      WEBAPPPRO_BUTTON_COLOR_2: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BUTTON_COLOR_2',
      ),
      WEBAPPPRO_SEPA_DEBIT_ALLOWED: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_SEPA_DEBIT_ALLOWED',
      ),
      WEBAPPPRO_SEPA_DEBIT_ACTIVATED: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_SEPA_DEBIT_ACTIVATED',
      ),
      background_desktop: localStorageService.getStyle(
        location.hostname,
        'WEBAPPPRO_BACKGROUND_DESKTOP',
      ),
      sepaError: [],
    }
  },
  async mounted() {
    // Chargez Stripe
    this.stripe = await loadStripe(process.env.VUE_APP_ENV_STRIPE_PUB_KEY)

    // Initialise Elements
    const elements = this.stripe.elements()
    this.ibanElement = elements.create('iban', {
      supportedCountries: ['SEPA'],
      placeholderCountry: 'FR',
      style: {
        base: {
          iconColor: '#C1C1C1', // Couleur de l'icône (violet pour correspondre à Vuetify par défaut)
          color: '#212121', // Couleur du texte (noir)
          fontWeight: '400', // Poids de la police similaire à Vuetify
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif', // Polices utilisées par Vuetify
          fontSize: '16px',
          height: '52px',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#9e9e9e', // Couleur du placeholder Vuetify
          },
          ':-webkit-autofill': {
            color: '#42a5f5', // Couleur lorsqu'un champ est auto-rempli
          },
          border: '2px solid #C1C1C1', // Bordure similaire à un v-text-field
          borderRadius: '8px', // Coins arrondis comme Vuetify
          padding: '10px', // Espacement interne pour correspondre à Vuetify
        },
        invalid: {
          color: '#ff5252', // Couleur rouge pour les erreurs
          iconColor: '#ff5252', // Couleur de l'icône en cas d'erreur
        },
      },
    })

    // Montez l'élément dans le DOM
    this.ibanElement.mount('#iban-element')
  },
  methods: {
    handleFocus(field) {
      this.isFocused[field] = true
    },
    handleBlur(field) {
      this.isFocused[field] = false
    },
    checkForm() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.errors = []
      const isValidEmail = emailRegex.test(this.email)
      if (!this.accountName) {
        this.errors.push('Le nom est obligatoire')
      } else if (!this.email) {
        this.errors.push('Saisissez votre email')
      } else if (!isValidEmail) {
        this.errors.push("Cette adresses e-mail n'est pas valide")
      } else {
        return true
      }
      return false
    },
    async handleSubmit() {
      this.checkForm()
      // Demander une clé secrete pour activer le SEPA
      requestService.get(`/order/sepa`).then(async response => {
        if (response && response.data && response.data.client_secret) {
          const { setupIntent, error } =
            await this.stripe.confirmSepaDebitSetup(
              response.data.client_secret,
              {
                payment_method: {
                  sepa_debit: this.ibanElement,
                  billing_details: {
                    name: this.accountName,
                    email: this.email,
                  },
                },
              },
            )

          if (error) {
            console.error(error.message)
            this.sepaError.push(error.message)
          } else if (setupIntent && setupIntent.payment_method) {
            console.log('SetupIntent créé avec succès :', setupIntent)
            requestService
              .get(
                `/order/sepa-debit-init/${setupIntent.payment_method}/${this.$route.params.partner_uid}`,
              )
              .then(() => {
                this.$router.push({
                  name: 'Wallet-agence',
                  params: {
                    partner_uid: this.$route.params.partner_uid,
                  },
                })
              })
          } else {
            this.sepaError.push("Echec de l'activation du prélèvement SEPA")
          }
        } else {
          this.sepaError.push("Echec de l'activation du prélèvement SEPA")
        }
      })
    },
  },
}
</script>
<style>
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  margin-right: auto;
  padding-right: 4px;
  padding: 15px;
}
.focused-border {
  border-color: #47d881 !important;
}
.focused-icon path {
  fill: #47d881 !important;
}
.iban-input {
  border: 2px solid #c1c1c1;
  border-radius: 8px;
  padding: 10px;
  font-family: Roboto, Open Sans, Segoe UI, sans-serif;
  font-size: 16px;
}

.iban-input:focus {
  border-color: #47d881;
  box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.2);
}

.iban-input.invalid {
  border-color: #ff5252;
}
.error {
  color: red;
  font-weight: bold;
}
</style>
