var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"text-center pa-7",style:({
    borderRadius: _vm.WEBAPPPRO_CARD_BORDER_ROUND,
  }),attrs:{"width":"600"}},[_c('div',{staticClass:"d-flex flex-column text-left"},[_c('p',{style:({
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        fontWeight: 600,
        fontSize: '16px',
        marginBottom: '5px',
      })},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('p',{style:({
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        fontWeight: 400,
        fontSize: '14px',
        fontStyle: 'italic',
        marginBottom: '10px',
      })},[_vm._v(" "+_vm._s(_vm.miniDescription)+" ")])]),_c('div',{staticClass:"d-flex flex-column"},[(_vm.titleX1)?_c('div',{staticClass:"d-flex flex-row justify-space-between mt-2 align-items-center",style:({ width: '100%' })},[_c('div',{staticClass:"text-left",style:({
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontWeight: 400,
          fontSize: '14px',
          flex: '1',
        })},[_vm._v(" "+_vm._s(_vm.titleX1)+" ")]),_c('div',{staticClass:"d-flex flex-row justify-space-between",style:({ flex: '2' })},[_c('label',{style:({ width: '137px', textAlign: 'left' })},[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX1Y1Value,"checked":_vm.radioValueX1 === _vm.checkboxX1Y1Value},on:{"change":function($event){return _vm.updateValueX1(_vm.checkboxX1Y1Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX1Y1Title)+" ")]),_c('label',{style:({ width: '137px', textAlign: 'left' })},[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX1Y2Value,"checked":_vm.radioValueX1 === _vm.checkboxX1Y2Value},on:{"change":function($event){return _vm.updateValueX1(_vm.checkboxX1Y2Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX1Y2Title)+" ")]),_c('label',{style:({ width: '137px', textAlign: 'left' })},[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX1Y3Value,"checked":_vm.radioValueX1 === _vm.checkboxX1Y3Value},on:{"change":function($event){return _vm.updateValueX1(_vm.checkboxX1Y3Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX1Y3Title)+" ")])])]):_vm._e(),(_vm.titleX2)?_c('div',{staticClass:"d-flex flex-row justify-space-between mt-2 align-items-center",style:({ width: '100%' })},[_c('div',{staticClass:"text-left",style:({
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontWeight: 400,
          fontSize: '14px',
          flex: '1',
        })},[_vm._v(" "+_vm._s(_vm.titleX2)+" ")]),_c('div',{staticClass:"d-flex flex-row justify-space-between",style:({ flex: '2' })},[_c('label',{style:({ width: '137px', textAlign: 'left' })},[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX2Y1Value,"checked":_vm.radioValueX2 === _vm.checkboxX2Y1Value},on:{"change":function($event){return _vm.updateValueX2(_vm.checkboxX2Y1Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX2Y1Title)+" ")]),_c('label',{style:({ width: '137px', textAlign: 'left' })},[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX2Y2Value,"checked":_vm.radioValueX2 === _vm.checkboxX2Y2Value},on:{"change":function($event){return _vm.updateValueX2(_vm.checkboxX2Y2Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX2Y2Title)+" ")]),_c('label',{style:({ width: '137px', textAlign: 'left' })},[_c('input',{attrs:{"type":"radio"},domProps:{"value":_vm.checkboxX2Y3Value,"checked":_vm.radioValueX2 === _vm.checkboxX2Y3Value},on:{"change":function($event){return _vm.updateValueX2(_vm.checkboxX2Y3Value)}}}),_vm._v(" "+_vm._s(_vm.checkboxX2Y3Title)+" ")])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }