var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"background-container",style:({
    backgroundImage:
      'url(' + require('@/assets/images/' + _vm.background_mobile) + ')',
    height: '100vh',
    width: '100vw',
    // overflow: 'auto',
    backgroundSize: 'cover',
  }),attrs:{"fluid":""}},[_c('div',{staticClass:"justify-center"},[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('v-btn',{style:({
          color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_1,
          boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_1,
          borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
          textTransform: 'none',
          marginBottom: '20px',
          padding: '25px 100px',
          fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
          fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
          fontWeight: 'bold',
          lineHeight: '18px',
          letterSpacing: '0em',
          textAlign: 'center',
          width: '317px',
          height: '52px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '300px',
        }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_1},on:{"click":_vm.SaveAndContinue}},[_vm._v(" Traitement plus rapide "),_c('br'),_vm._v("Code ephoto disponible dans 15 mns ")])],1)]),_c('div',{staticClass:"camera-shoot justify-center d-flex my-5 align-center"},[_c('v-btn',{staticClass:"centered-button mt-2",style:({
        color: _vm.WEBAPPPRO_BUTTON_TEXT_COLOR_2,
        boxShadow: _vm.WEBAPPPRO_BUTTON_SHADOW_2,
        borderRadius: _vm.WEBAPPPRO_BUTTON_BORDER_ROUND,
        'text-transform': 'none',
        padding: '25px 100px',
        fontFamily: _vm.WEBAPPPRO_FONT_FAMILY_3,
        fontSize: _vm.WEBAPPPRO_BUTTON_FONT_SIZE,
        'font-weight': 'bold',
        'line-height': '18px',
        'letter-spacing': '0em',
        'text-align': 'center',
        marginTop: '10px',
        height: '52px',
        width: '317px',
      }),attrs:{"color":_vm.WEBAPPPRO_BUTTON_COLOR_2},on:{"click":_vm.takePhoto}},[_vm._v(" Reprendre ma photo ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }